import '../styles/main.scss';

import '../styles/cards.scss';
import '../styles/custom-style.scss';
import '../styles/devices.scss';
import '../styles/video.scss';
import '../styles/citation.scss';
import '../styles/form.scss';
import '../styles/sliders.scss';

import 'animate.css';

import './front/animateFront.js';