document.addEventListener('DOMContentLoaded', () => {

  // Fonction pour appliquer les animations
  const animateCSS = (node, animation, prefix = 'animate__') =>
    new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`;
      node.classList.add(`${prefix}animated`, animationName);

      function handleAnimationEnd(event) {
        event.stopPropagation();
        node.classList.remove(`${prefix}animated`, animationName);
        resolve('Animation ended');
      }

      node.addEventListener('animationend', handleAnimationEnd, { once: true });
    });

  // Observer pour détecter lorsque l'élément entre dans le viewport
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const element = entry.target;
        const animation = element.getAttribute('data-animation');

        animateCSS(element, animation)
          .then(() => {
            console.log('Animation ended');
          })
          .catch((error) => {
            console.error(error);
          });

        // Optionnel : arrêter d'observer l'élément après l'animation
        observer.unobserve(element);
      }
    });
  }, { threshold: 0.1 });

  document.querySelectorAll('[data-animation]').forEach((element) => {
    observer.observe(element);
  });

  // Animations Texts
  const revealTextElements = document.querySelectorAll('.reveal-text');
  const parallaxElements = document.querySelectorAll('.is-style-parallax');
  const initialOpacity = 0.2; // Opacité initiale

  function handleScroll() {

    const triggerOffset = window.innerHeight / 4; // Nombre de pixels visibles avant que l'animation commence

    revealTextElements.forEach(el => {
      const rect = el.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Commence l'animation seulement quand 'triggerOffset' pixels sont visibles
      const scrolledPercentage = (windowHeight - rect.top - triggerOffset) / (windowHeight - rect.height - triggerOffset);

      if (scrolledPercentage > 0) {
        const spans = el.querySelectorAll('span');
        spans.forEach((span, index) => {
          const spansInElement = el.querySelectorAll('span').length;
          const spanOffset = (index / spansInElement);
          const progress = (scrolledPercentage - spanOffset) / (1 / spansInElement);
          const opacity = Math.min(Math.max(progress, 0), 1);
          span.style.opacity = Math.max(opacity, initialOpacity);
        });
      }
    });

    // ... votre code pour les éléments parallax
  }

  function wrapTextWithSpans(node) {
    node.childNodes.forEach(child => {
      if (child.nodeType === Node.TEXT_NODE) {
        const text = child.textContent;
        const newHTML = text.split('').map(letter => {
          const isSpace = letter === ' ';
          return `<span class="${isSpace ? 'space' : ''}">${letter}</span>`;
        }).join('');
        const fragment = document.createElement('div');
        fragment.classList.add('word');
        fragment.innerHTML = newHTML;
        child.replaceWith(fragment);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        wrapTextWithSpans(child); // Récursion sur les éléments enfants
      }
    });
  }

  revealTextElements.forEach(el => {
    // Vérifier si l'élément a déjà été traité
    if (!el.classList.contains('processed')) {
      wrapTextWithSpans(el);
      el.classList.add('processed');
    }
  });

  window.addEventListener('scroll', handleScroll);
  handleScroll(); // Initial check

});
